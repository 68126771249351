<template lang="pug">
    .iframe(v-html="contents.content")
        //- iframe(:src="", v-bind="contents.attributes")
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-iframe",
    props,
}
</script>

<style lang="stylus" scoped src="./Iframe.styl"></style>
